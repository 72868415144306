@use "styles/utils/media.module";

.section {
	max-width: var(--container-size);
	margin: 80px auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;

	@include media.min-width(lg) {
		margin: 0 auto 160px;
	}
}

.heading {
	margin-bottom: 40px;
	grid-column: 1 / -1;
	grid-row: 1;
	padding: 0 32px;

	@include media.min-width(lg) {
		margin-bottom: 80px;
	}

	@include media.min-width(xl) {
		padding: 0;
	}
}

.content {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
	padding: 0 32px 0 64px;
	grid-gap: 40px;

	@include media.min-width(lg) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: 90px;
		padding: 0 120px;
	}
}

.stepNumber {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border: var(--border-transparant);
	border-radius: 50%;
}
