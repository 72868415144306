@use "styles/utils/media.module";

.section {
	max-width: 1124px;
	margin: 80px auto;

	@include media.min-width(lg) {
		margin: 160px auto;
	}
}

.wines {
	display: grid;
	margin-bottom: 84px;
	grid-gap: 44px;
	padding: 0 8px;

	@include media.min-width(lg) {
		grid-template-columns: repeat(3, min(280px, 33.333vh));
		grid-row: 2;
		grid-column: 1 / -1;
		grid-gap: 76px;
		justify-content: space-evenly;
		margin-bottom: 0;
		padding: 0;
	}
}

.heading {
	padding: 0 32px;

	@include media.min-width(xl) {
		padding: 0;
	}
}

.introText {
	display: flex;
	margin-bottom: 32px;
	max-width: 700px;
	padding: 0 32px 0 64px;

	@include media.min-width(lg) {
		margin-bottom: 80px;
		padding: 0 0 0 120px;
	}
}
