@use "styles/utils/media.module";

.section {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 400px;

	overflow: hidden;
	margin-bottom: 80px;
	position: relative;

	@include media.min-width(lg) {
		grid-template-columns: 1fr 1fr;

		min-height: 100vh;
		grid-template-rows: auto;
		margin-bottom: 160px;
	}
}

.content__wrapper {
	grid-row: 1;
	grid-column: 1 / -1;
	display: inline-block;
	height: fit-content;
	align-self: center;
	padding: 150px 0 0;

	@include media.min-width(lg) {
		height: 100%;
		grid-column: 1;
		padding: 0;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 40px;
	max-width: 85%;
	position: relative;
	padding: 0 32px;

	@include media.min-width(lg) {
		max-width: none;
		flex-basis: 50%;
		padding: 150px 0 110px 80px;
		justify-content: center;
	}
}

.title {
	margin-bottom: 16px;

	@include media.min-width(lg) {
		margin-bottom: 24px;
	}
}

.text {
	max-width: 480px;

	@include media.min-width(lg) {
		max-width: 70%;
	}
}

.image {
	background-size: auto 98%;
	background-position: -25% 262%;
	background-repeat: no-repeat;
	margin-top: 0px;
	margin-left: -900px;
	grid-row: 1 / -1;

	@include media.min-width(lg) {
		background-size: cover;
		background-position: left top;
		background-repeat: no-repeat;
		margin-left: -200px;
		margin-top: 0;
	}

	@include media.min-width(xl) {
		margin-left: -250px;
	}

	@include media.min-width(xxl) {
		margin-left: -500px;
	}
}

// Scroll gradient for breakpoint up to LG
.scroll_gradient {
	position: absolute;
	bottom: 10px;
	width: 150px;
	height: 150px;
	@include media.min-width(lg) {
		display: none;
	}
}

// Scroll indicator for breakpoint up to LG
.scroll_indicator {
	position: absolute;
	left: 40px;
	bottom: 0px;
	cursor: default;
	@include media.min-width(lg) {
		display: none;
	}
}

// Scroll gradient for breakpoint LG and higher
.scroll_gradientDesktop {
	display: none;
}

// Scroll container for breakpoint LG and higher
.scroll_indicatorDesktop {
	--transform-x: -120px;
	transform: rotate(-90deg) translateX(var(--transform-x)) translateY(5px);
	position: relative;
	left: 0;
	bottom: auto;
	width: fit-content;
	cursor: default;

	@include media.min-height(900px) {
		--transform-x: -30vh;
	}

	@include media.min-height(1000px) {
		--transform-x: -35vh;
	}

	@include media.min-height(1100px) {
		--transform-x: -40vh;
	}

	@include media.max-width(lg) {
		display: none;
	}
}
