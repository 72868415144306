@use "styles/utils/media.module";

.item {
	display: block;

	&__images {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		aspect-ratio: 1/1;

		&__bg {
			grid-row: 1;
			grid-column: 1;
			justify-self: center;
			object-fit: contain;
			height: auto;
			width: 100%;

			@include media.min-width(xl) {
				width: 100%;
				height: auto;
			}
		}

		&__bottle {
			grid-row: 1;
			grid-column: 1;
			justify-self: center;
			align-self: flex-end;
			width: 33.333%;
			height: 100%;
			filter: drop-shadow(0px 16px 40px #f8f7f7)
				drop-shadow(16px 16px 40px rgba(82, 75, 75, 0.4));
			object-fit: contain;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__buttons {
		display: flex;
		gap: 12px;
		justify-content: center;
	}

	&__h2 {
		text-align: center;
		margin-bottom: 4px;
		margin-top: 36px;
		color: var(--wsw-100);
	}

	&__h4 {
		text-align: center;
		text-wrap: balance;
		color: var(--wsw-100);
	}

	&__price {
		text-align: center;
		margin-top: 16px;
	}

	&__button__price {
		gap: 12px;
	}
}
