@use "styles/utils/media.module";

.el {
	position: fixed;
	width: 120px;
	height: 120px;
	left: 0px;
	bottom: 0;

	background: linear-gradient(
		236.49deg,
		rgba(82, 75, 75, 0) 51.14%,
		rgba(82, 75, 75, 0.6) 100%
	);

	@include media.min-width(lg) {
		display: none;
	}
}

.container {
	position: fixed;
	bottom: calc(10px + env(safe-area-inset-bottom));
	transform: rotate(-90deg);
	transform-origin: left center;
	transition: all 0.3s ease;

	.indicator {
		display: flex;
		align-items: center;
		padding: 11px 18px 11px 12px;
		gap: 6px;
		color: var(--wsw-0);

		> span {
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.04em;
			text-transform: uppercase;
		}

		@include media.min-width(lg) {
			color: var(--wsw-75);
		}
	}
}
