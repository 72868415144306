.root {
	--size: 24px;
	height: var(--size);
	width: var(--size);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	flex: 0 0 var(--size);
	border-radius: 0;
	border: 1px solid var(--wsw-100);
	background-color: transparent;
	color: var(--wsw-0);

	&[data-state="checked"] {
		background-color: var(--wsw-100);
	}

	&:focus-visible {
		outline-color: var(--wsw-pink);
	}
}

.indicator {
	--inner-size: 14px;
	height: var(--inner-size);
	width: var(--inner-size);
	background-color: green;
	box-sizing: border-box;
	transition: background-color 0.2s ease-in-out;
}

.label {
	font: var(--font-body);
	letter-spacing: 0.02em;
	margin: 0;
	color: var(--wsw-100);
}
