@use "styles/utils/media.module";

.container {
	width: auto;
}

.input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	background-color: transparent;
	border: 0;
	border-bottom: var(--border);
	height: 43px;
	font: var(--font-body);
	letter-spacing: 0.02em;
	margin: 0;
	color: var(--wsw-100);
	border-radius: 0;
	width: 100%;

	@include media.min-width(xl) {
		font-size: 18px;
	}

	&:focus-visible {
		outline: 1px auto var(--wsw-yellow);
	}

	&:focus {
		outline: none;
		border-bottom-color: var(--wsw-pink);
	}

	&.error {
		border-bottom: 1px solid var(--wsw-red);
	}

	&::placeholder {
		font: var(--font-xxs);
		font-feature-settings: var(--font-feature-settings-sans);
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: var(--wsw-transparent);
	}

	&:-webkit-text-fill-color {
		box-shadow: var(--yellow);
		-webkit-box-shadow: var(--yellow);
	}
}
