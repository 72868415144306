@use "styles/utils/media.module";

.container {
	display: block;

	.label {
		font: var(--font-xxs);
		font-feature-settings: var(--font-feature-settings-sans);
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: var(--wsw-transparent);
	}

	.textarea {
		width: 100%;
		resize: vertical;
		background-color: transparent;
		border: 0;
		border-bottom: var(--border);
		min-height: 64px;
		font: var(--font-body);
		letter-spacing: 0.02em;
		margin: 0;
		color: var(--wsw-100);
		padding: 0;
		border-radius: 0;
		&:focus {
			outline: 0;
			border-color: var(--wsw-pink);
		}

		&:focus-visible {
			// outline: revert;
			outline-color: var(--wsw-pink);
		}

		&.error {
			border-bottom: 1px solid var(--wsw-red);
		}

		&::placeholder {
			font: var(--font-xxs);
			font-feature-settings: var(--font-feature-settings-sans);
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: var(--wsw-transparent);
		}

		&:-webkit-text-fill-color {
			box-shadow: var(--yellow);
			-webkit-box-shadow: var(--yellow);
		}

		@include media.min-width(xl) {
			font-size: 18px;
		}
	}
}
