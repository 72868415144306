.switch {
	&:hover {
		cursor: pointer;
	}

	&__label {
		flex-grow: 1;
	}

	&__title {
		font: var(--font-body-bold);
		color: var(--wsw-100);
	}

	&__subtitle {
		font: var(--font-body-sm);
	}

	&__wrapper {
		position: relative;
		display: flex;
		width: 48px;
		height: 26px;
		padding: 3px;
		border: var(--border-gray);
		background-color: var(--wsw-0);
		justify-content: flex-start;
		transition: all 1s ease;

		&:hover {
			cursor: pointer;
		}

		&[data-state="checked"] {
			background-color: var(--wsw-100);

			.switch__thumb {
				background-color: var(--wsw-0);
				transform: translate3d(22px, 0, 0);
			}
		}
	}

	&__thumb {
		position: relative;
		width: 18px;
		height: 18px;
		background-color: var(--wsw-75);
		transition: transform 0.4s ease, background-color 0.2s ease;
	}

	&.isDisabled {
		.switch__title {
			color: var(--wsw-75);
		}

		&:hover {
			cursor: auto;
		}

		.switch__wrapper {
			cursor: auto;
			background-color: var(--wsw-50);
			border: var(--border-transparant);
		}

		.switch__thumb {
			background-color: var(--wsw-0);
			transform: translate3d(22px, 0, 0);
		}
	}
}
