@use "styles/utils/media.module";

.section {
	max-width: 560px;
	margin: 80px auto;
	padding: 0 32px;

	@include media.min-width(lg) {
		margin: 160px auto;
		padding: 0;
	}
}

.icon {
	flex: 0 0 auto;
}
