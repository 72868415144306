@use "styles/utils/media.module";

.form {
	position: relative;
	padding-bottom: 0px;
	overflow-x: clip;

	&.formWithImage {
		padding-bottom: 300px;

		background-size: 200% auto;
		background-repeat: no-repeat;
		background-position: left bottom;

		@include media.min-width(md) {
			background-size: 150% auto;
			padding-bottom: 400px;
		}

		@include media.min-width(lg) {
			background-size: 100% auto;
			padding-bottom: 500px;
		}
	}
}

.section {
	max-width: var(--container-size);
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	position: relative;
}

.content {
	grid-column: 1/-1;
	margin-right: 32px;
	background-color: var(--yellow);
	padding: 56px;
	position: relative;
	z-index: 2;

	p {
		color: var(--wsw-transparent);
	}

	@include media.min-width(lg) {
		padding: 80px;
		grid-column: 1 / 2;
		grid-row: 1;
		margin-right: 0;
	}
}

.illustration {
	height: auto;
	max-width: 200vw;
	// width: 100%;
	position: absolute;
	top: auto;
	left: 0;
	bottom: 0;
	transform: translateY(100%);
	@include media.min-width(lg) {
		height: 100%;
		bottom: auto;
		top: 0;
	}
}

.form__button {
	align-self: stretch;
}
