.item {
	--size: 24px;
	height: var(--size);
	width: var(--size);
	border-radius: 50%;
	border: 1px solid var(--wsw-100);
	background-color: inherit;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: inherit;

	&__root {
		cursor: pointer;
	}

	&:disabled {
		border: 1px solid var(--wsw-50);
		cursor: not-allowed;

		& + .item__root {
			cursor: not-allowed;
		}
	}

	&__indicator {
		--inner-size: 16px;
		height: var(--inner-size);
		width: var(--inner-size);
		background-color: var(--wsw-100);
		box-sizing: border-box;
		display: block;
		border-radius: inherit;
		// transition: background-color 0.2s ease-in-out;
	}

	&__label {
		cursor: inherit;
	}
}
