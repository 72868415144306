@use "styles/utils/media.module";

.dialog {
	&__overlay {
		background-color: rgba(0, 0, 0, 0.3);
		position: fixed;
		z-index: 500;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 16px;

		display: grid;
		place-items: center;
		overflow-y: auto;
	}

	&__content {
		position: relative;
		background-color: #fff;
		height: auto;
		width: 100%;
		max-width: 640px;
		border: 1px solid var(--wsw-100);
		box-shadow: 4px 4px 0px var(--wsw-100);
		padding: 64px 24px 24px;

		@include media.min-width(md) {
			padding: 72px 40px 40px;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
	}

	&__close {
		border: 0;
		background-color: transparent;
	}

	&__button__close {
		position: absolute;
		right: 24px;
		top: 24px;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;

		padding-top: 24px;
	}
}
