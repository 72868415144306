.select {
	border: 0;
	border-bottom: 1px solid var(--wsw-100);
	background-color: inherit;
	padding: 16px 0;
	cursor: pointer;

	// text styling
	font: var(--font-xxs);
	font-feature-settings: var(--font-feature-settings-sans);
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: var(--wsw-transparent);
}
