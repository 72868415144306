@use "./Dialog.module.scss";
@use "styles/utils/media.module";

.dialog {
	&__buttons {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		gap: 20px;

		padding-top: 24px;

		& > * {
			flex-grow: 1;

			@include media.min-width(md) {
				flex: 0 0 calc(50% - 10px);
			}

			button {
				width: -webkit-fill-available;
			}
		}
	}

	&__isAddToCartButton {
		flex-direction: column-reverse;
		gap: 16px;
	}
}
